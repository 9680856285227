body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: '#f0eeeb';
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

/*  Used this class initiallly, else it was showing completely black screen 
    throughout the app */
#zmmtg-root {
    display: none;
    z-index: 10000;
}

.link {
    color: #007bff;
    cursor: pointer;
    font-weight: bold;
    text-decoration: none !important;
}

/* Sidebar css */
.navlink {
    color: rgba(0, 0, 0, 0.87);
    text-decoration: none;
}
.navlink :hover {
    text-decoration: none !important;
    color: #007bff;
}

.flexRow {
    display: flex;
    flex-direction: row;
}

.centerDiv {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.endDiv {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    margin-right: 20px;
}
/* Forms Container */
.form-div {
    margin-top: 36px;
    margin-bottom: 100px;
    border: 1px solid #eee;
    background: white;
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

/* List card */
.list-div {
    width: 100%;
    margin-top: 30px;
}
.card-div {
    margin-bottom: 25px;
}
.card-body {
    padding: 5px 5px;
    margin-top: 20px;
    border-radius: 5px;
    display: flex;
    -webkit-box-shadow: 0 2px 10px 0 #777;
    -moz-box-shadow: 0 2px 10px 0 #777;
    box-shadow: 0 2px 10px 0 #777;
}
.card-media-div {
    /* border: 1px solid #777; */
    border-radius: 5px;
}
.media-size {
    width: 400px;
    height: 200px;
}
.card-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px 20px;
    flex: 1;
}
.card-text {
    font-size: 16px;
    margin-bottom: 5px;
}
.vertical {
    flex-direction: column;
}
.card-icon {
    cursor: pointer;
    color: black;
}
.card-footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.user-deleted {
    pointer-events: none;
    cursor: not-allowed;
    background-color: rgb(219, 210, 210);
}

.endContent {
    display: 'flex';
    justify-content: 'flex-end';
}

.form-label {
    display: flex;
    align-items: center;
    justify-content: center;
}

.form-label-start {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
